<template>
  <div class="sp-page">
    <el-collapse
      v-if="form.answer_cfg"
      v-model="form.actName" accordion>
        <!-- <el-collapse-item name="chapter"> -->
          <!-- <template slot="title">
            <div class="answer-book-style-header">
              <span class="answer-book-style-header-title p-r-10">标题</span>
              <el-tooltip class="item" effect="dark" placement="top" :open-delay="200">
                <template slot="content">
                  <span class="p-r-10" v-if="chapter_cfg.text">{{ chapter_cfg.text }}</span>
                  <span class="p-r-10" v-else style="color: red">空标题</span>
                  <span class="p-r-10">{{ chapter_cfg.font_size || '二号' }}</span>
                  <span class="p-r-10">{{ findKey(chapter_cfg.font_family_cn, fontFamilyCn) || '黑体' }}</span>
                  <span class="p-r-10">{{ findKey(chapter_cfg.font_family_en, fontFamilyEn) || '白正' }}</span>
                  <span class="p-r-10">{{ findKey(chapter_cfg.text_align, fontMargin) || '居中' }}</span>
                </template>
                <span class="answer-book-style-header-desc">
                  <span class="p-r-10" v-if="chapter_cfg.text">{{ chapter_cfg.text }}</span>
                  <span class="p-r-10" v-else style="color: red">空标题</span>
                  <span class="p-r-10">{{ chapter_cfg.font_size || '二号' }}</span>
                  <span class="p-r-10">{{ findKey(chapter_cfg.font_family_cn, fontFamilyCn) || '黑体' }}</span>
                  <span class="p-r-10">{{ findKey(chapter_cfg.font_family_en, fontFamilyEn) || '白正' }}</span>
                  <span class="p-r-10">{{ findKey(chapter_cfg.text_align, fontMargin) || '居中' }}</span>
                </span>
              </el-tooltip>
            </div>
          </template> -->
          <!-- <el-form label-position="right" label-width="160px" size="mini" class="m-t-10">
            <el-form-item label="标题内容：">
              <el-input v-model="chapter_cfg.text" style="width: 400px;"></el-input>
            </el-form-item>
            <el-form-item label="字号：">
              <el-select v-model="chapter_cfg.font_size">
                <el-option v-for="item in fontSize" :key="item.value" :value="item.value" :label="item.key"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="中文字体：">
              <el-select v-model="chapter_cfg.font_family_cn">
                <el-option
                  v-for="(value, key) in fontFamilyCn"
                  :key="value"
                  :label="key"
                  :value="value" />
              </el-select>
            </el-form-item>
            <el-form-item label="英文字体：">
              <el-select v-model="chapter_cfg.font_family_en">
                <el-option
                  v-for="(value, key) in fontFamilyEn"
                  :key="value"
                  :label="key"
                  :value="value" />
              </el-select>
            </el-form-item>
            <el-form-item label="对齐方式：">
              <el-select v-model="chapter_cfg.text_align">
                <el-option
                  v-for="(value, key) in fontMargin"
                  :key="value"
                  :label="key"
                  :value="value" />
              </el-select>
            </el-form-item>
          </el-form> -->
        <!-- </el-collapse-item> -->
      <el-collapse-item name="choice">
        <template slot="title">
          <div class="answer-book-style-header">
            <span class="answer-book-style-header-title p-r-10">选择题</span>
              <el-tooltip class="item" effect="dark" placement="top" :open-delay="200">
                <template slot="content">
                  <template v-if="choice_cfg.answer_mode">
                    <span class="p-r-10" v-if="choice_cfg.answer_mode === 'default'">正常模式</span>
                    <span class="p-r-10" v-else>答案组模式</span>
                  </template>
                  <template v-if="choice_cfg.answer_mode === 'default'">
                    <span class="p-r-10" v-if="choice_cfg.display_analysis === true">有解析</span>
                    <span class="p-r-10" v-if="choice_cfg.display_analysis === false">无解析</span>
                    <template v-if="choice_cfg.question_arrangement_mode">
                      <span class="p-r-10" v-if="choice_cfg.question_arrangement_mode === 'break_line'">一行一题</span>
                      <span class="p-r-10" v-else>一行多题</span>
                    </template>
                    <template v-if="choice_cfg.display_analysis">
                      <template v-if="choice_cfg.answer_analysis_order">
                        <span class="p-r-10" v-if="choice_cfg.answer_analysis_order === 'answer_first'">先答案后解析</span>
                        <span class="p-r-10" v-else>先解析后答案</span>
                      </template>
                      <template v-if="choice_cfg.answer_analysis_arrangement_mode">
                        <span class="p-r-10" v-if="choice_cfg.answer_analysis_arrangement_mode === 'break_line'">解析换行</span>
                        <span class="p-r-10" v-else>解析不换行</span>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="typeof choice_cfg.answer_group_cfg.max_num === 'number'">
                      <span class="p-r-10">{{choice_cfg.answer_group_cfg.max_num}}个 / 组</span>
                    </template>
                    <template v-if="typeof choice_cfg.answer_group_cfg.spacing === 'number'">
                      <span class="p-r-10">间距{{choice_cfg.answer_group_cfg.spacing}}字</span>
                    </template>
                    <template v-if="choice_cfg.answer_group_cfg.delimiter">
                      <span class="p-r-10">题号：1{{choice_cfg.answer_group_cfg.delimiter}}n</span>
                    </template>
                  </template>
                </template>
                <span class="answer-book-style-header-desc">
                  <template v-if="choice_cfg.answer_mode">
                    <span class="p-r-10" v-if="choice_cfg.answer_mode === 'default'">正常模式</span>
                    <span class="p-r-10" v-else>答案组模式</span>
                  </template>
                  <template v-if="choice_cfg.answer_mode === 'default'">
                    <span class="p-r-10" v-if="choice_cfg.display_analysis === true">有解析</span>
                    <span class="p-r-10" v-if="choice_cfg.display_analysis === false">无解析</span>
                    <template v-if="choice_cfg.question_arrangement_mode">
                      <span class="p-r-10" v-if="choice_cfg.question_arrangement_mode === 'break_line'">一行一题</span>
                      <span class="p-r-10" v-else>一行多题</span>
                    </template>
                    <template v-if="choice_cfg.display_analysis">
                      <template v-if="choice_cfg.answer_analysis_order">
                        <span class="p-r-10" v-if="choice_cfg.answer_analysis_order === 'answer_first'">先答案后解析</span>
                        <span class="p-r-10" v-else>先解析后答案</span>
                      </template>
                      <template v-if="choice_cfg.answer_analysis_arrangement_mode">
                        <span class="p-r-10" v-if="choice_cfg.answer_analysis_arrangement_mode === 'break_line'">解析换行</span>
                        <span class="p-r-10" v-else>解析不换行</span>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="typeof choice_cfg.answer_group_cfg.max_num === 'number'">
                      <span class="p-r-10">{{choice_cfg.answer_group_cfg.max_num}}个 / 组</span>
                    </template>
                    <template v-if="typeof choice_cfg.answer_group_cfg.spacing === 'number'">
                      <span class="p-r-10">间距{{choice_cfg.answer_group_cfg.spacing}}字</span>
                    </template>
                    <template v-if="choice_cfg.answer_group_cfg.delimiter">
                      <span class="p-r-10">题号：1{{choice_cfg.answer_group_cfg.delimiter}}n</span>
                    </template>
                  </template>
                </span>
              </el-tooltip>
          </div>
        </template>
        <el-form label-position="right" label-width="160px" size="mini" class="m-t-10">
          <el-form-item label="答案模式：">
            <el-radio-group v-model="choice_cfg.answer_mode">
              <el-radio label="default">正常模式</el-radio>
              <el-radio label="group">答案组模式</el-radio>
            </el-radio-group>
          </el-form-item>
          <template v-if="choice_cfg.answer_mode === 'default'">
            <el-form-item label="是否显示解析：">
              <el-radio-group v-model="choice_cfg.display_analysis">
                <el-radio :label="true">显示</el-radio>
                <el-radio :label="false">不显示</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="试题间排列效果：">
                <el-select v-model="choice_cfg.question_arrangement_mode" style="width: 100px;">
                  <el-option label="换行" value="break_line"></el-option>
                  <el-option label="空1字" value="1"></el-option>
                  <el-option label="换行且无解析自动接排" value="break_line_auto"></el-option>
                </el-select>
              </el-form-item>
            <template v-if="choice_cfg.display_analysis">
              <el-form-item label="答案解析顺序：">
                <el-radio-group v-model="choice_cfg.answer_analysis_order">
                  <el-radio label="answer_first">先答案后解析</el-radio>
                  <el-radio label="analysis_first">先解析后答案</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="答案解析排列效果：">
                <el-select v-model="choice_cfg.answer_analysis_arrangement_mode" style="width: 100px;">
                  <el-option label="换行" value="break_line"></el-option>
                  <el-option label="空1字" value="1"></el-option>
                  <el-option label="空2字" value="2"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </template>
          <template v-else>
            <el-form-item label="答案组答案数：">
              <el-input v-model.number="choice_cfg.answer_group_cfg.max_num" type="number" style="width: 100px;"></el-input><span class="m-l-10">个 / 组</span>
            </el-form-item>
            <el-form-item label="答案组间距：">
              <el-input v-model.number="choice_cfg.answer_group_cfg.spacing" type="number" style="width: 100px;"></el-input><span class="m-l-10">字</span>
            </el-form-item>
            <el-form-item label="答案组题号连接符：">
              <el-select v-model="choice_cfg.answer_group_cfg.delimiter" style="width: 100px;">
                <el-option label="~" value="~"></el-option>
                <el-option label="-" value="-"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-form>
      </el-collapse-item>
      <el-collapse-item name="blank">
        <template slot="title">
          <div class="answer-book-style-header">
            <span class="answer-book-style-header-title p-r-10">填空题</span>
            <el-tooltip class="item" effect="dark" placement="top" :open-delay="200">
              <template slot="content">
                <span class="p-r-10" v-if="blank_cfg.display_analysis === true">有解析</span>
                <span class="p-r-10" v-if="blank_cfg.display_analysis === false">无解析</span>
                <template v-if="blank_cfg.question_arrangement_mode">
                  <span class="p-r-10" v-if="blank_cfg.question_arrangement_mode === 'break_line'">一行一题</span>
                  <span class="p-r-10" v-else>一行多题</span>
                </template>
                <template v-if="blank_cfg.display_analysis === true">
                  <template v-if="blank_cfg.answer_analysis_order">
                    <span class="p-r-10" v-if="blank_cfg.answer_analysis_order === 'answer_first'">先答案后解析</span>
                    <span class="p-r-10" v-else>先解析后答案</span>
                  </template>
                  <template v-if="blank_cfg.answer_analysis_arrangement_mode">
                    <span class="p-r-10" v-if="blank_cfg.answer_analysis_arrangement_mode === 'break_line'">解析换行</span>
                    <span class="p-r-10" v-else>解析不换行</span>
                  </template>
                </template>
              </template>
              <span class="answer-book-style-header-desc">
                <span class="p-r-10" v-if="blank_cfg.display_analysis === true">有解析</span>
                <span class="p-r-10" v-if="blank_cfg.display_analysis === false">无解析</span>
                <template v-if="blank_cfg.question_arrangement_mode">
                  <span class="p-r-10" v-if="blank_cfg.question_arrangement_mode === 'break_line'">一行一题</span>
                  <span class="p-r-10" v-else>一行多题</span>
                </template>
                <template v-if="blank_cfg.display_analysis === true">
                  <template v-if="blank_cfg.answer_analysis_order">
                    <span class="p-r-10" v-if="blank_cfg.answer_analysis_order === 'answer_first'">先答案后解析</span>
                    <span class="p-r-10" v-else>先解析后答案</span>
                  </template>
                  <template v-if="blank_cfg.answer_analysis_arrangement_mode">
                    <span class="p-r-10" v-if="blank_cfg.answer_analysis_arrangement_mode === 'break_line'">解析换行</span>
                    <span class="p-r-10" v-else>解析不换行</span>
                  </template>
                </template>
              </span>
            </el-tooltip>
          </div>
        </template>
        <el-form label-position="right" label-width="160px" size="mini" class="m-t-10">
          <el-form-item label="是否显示解析：">
            <el-radio-group v-model="blank_cfg.display_analysis">
              <el-radio :label="true">显示</el-radio>
              <el-radio :label="false">不显示</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="试题间排列效果：">
            <el-select v-model="blank_cfg.question_arrangement_mode" style="width: 100px;">
              <el-option label="换行" value="break_line"></el-option>
              <el-option label="空1字" value="1"></el-option>
              <el-option label="换行且无解析自动接排" value="break_line_auto"></el-option>
            </el-select>
          </el-form-item>
          <template v-if="blank_cfg.display_analysis === true">
            <el-form-item label="答案解析顺序：">
              <el-radio-group v-model="blank_cfg.answer_analysis_order">
                <el-radio label="answer_first">先答案后解析</el-radio>
                <el-radio label="analysis_first">先解析后答案</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="答案解析排列效果：">
              <el-select v-model="blank_cfg.answer_analysis_arrangement_mode" style="width: 100px;">
                <el-option label="换行" value="break_line"></el-option>
                <el-option label="空1字" value="1"></el-option>
                <el-option label="空2字" value="2"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-form>
      </el-collapse-item>
      <el-collapse-item name="true_or_false">
        <template slot="title">
          <div class="answer-book-style-header">
            <span class="answer-book-style-header-title p-r-10">判断题</span>
            <el-tooltip class="item" effect="dark" placement="top" :open-delay="200">
              <template slot="content">
                <span class="p-r-10" v-if="true_or_false_cfg.display_analysis === true">有解析</span>
                <span class="p-r-10" v-if="true_or_false_cfg.display_analysis === false">无解析</span>
                <template v-if="true_or_false_cfg.question_arrangement_mode">
                  <span class="p-r-10" v-if="true_or_false_cfg.question_arrangement_mode === 'break_line'">一行一题</span>
                  <span class="p-r-10" v-else>一行多题</span>
                </template>
                <template v-if="true_or_false_cfg.display_analysis === true">
                  <template v-if="true_or_false_cfg.answer_analysis_order">
                    <span class="p-r-10" v-if="true_or_false_cfg.answer_analysis_order === 'answer_first'">先答案后解析</span>
                    <span class="p-r-10" v-else>先解析后答案</span>
                  </template>
                  <template v-if="true_or_false_cfg.answer_analysis_arrangement_mode">
                    <span class="p-r-10" v-if="true_or_false_cfg.answer_analysis_arrangement_mode === 'break_line'">解析换行</span>
                    <span class="p-r-10" v-else>解析不换行</span>
                  </template>
                </template>
              </template>
              <span class="answer-book-style-header-desc">
                <span class="p-r-10" v-if="true_or_false_cfg.display_analysis === true">有解析</span>
                <span class="p-r-10" v-if="true_or_false_cfg.display_analysis === false">无解析</span>
                <template v-if="true_or_false_cfg.question_arrangement_mode">
                  <span class="p-r-10" v-if="true_or_false_cfg.question_arrangement_mode === 'break_line'">一行一题</span>
                  <span class="p-r-10" v-else>一行多题</span>
                </template>
                <template v-if="true_or_false_cfg.display_analysis === true">
                  <template v-if="true_or_false_cfg.answer_analysis_order">
                    <span class="p-r-10" v-if="true_or_false_cfg.answer_analysis_order === 'answer_first'">先答案后解析</span>
                    <span class="p-r-10" v-else>先解析后答案</span>
                  </template>
                  <template v-if="true_or_false_cfg.answer_analysis_arrangement_mode">
                    <span class="p-r-10" v-if="true_or_false_cfg.answer_analysis_arrangement_mode === 'break_line'">解析换行</span>
                    <span class="p-r-10" v-else>解析不换行</span>
                  </template>
                </template>
              </span>
            </el-tooltip>
          </div>
        </template>
        <el-form label-position="right" label-width="160px" size="mini" class="m-t-10">
          <el-form-item label="是否显示解析：">
            <el-radio-group v-model="true_or_false_cfg.display_analysis">
              <el-radio :label="true">显示</el-radio>
              <el-radio :label="false">不显示</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="试题间排列效果：">
            <el-select v-model="true_or_false_cfg.question_arrangement_mode" style="width: 100px;">
              <el-option label="换行" value="break_line"></el-option>
              <el-option label="空1字" value="1"></el-option>
              <el-option label="换行且无解析自动接排" value="break_line_auto"></el-option>
            </el-select>
          </el-form-item>
          <template v-if="true_or_false_cfg.display_analysis === true">
            <el-form-item label="答案解析顺序：">
              <el-radio-group v-model="true_or_false_cfg.answer_analysis_order">
                <el-radio label="answer_first">先答案后解析</el-radio>
                <el-radio label="analysis_first">先解析后答案</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="答案解析排列效果：">
              <el-select v-model="true_or_false_cfg.answer_analysis_arrangement_mode" style="width: 100px;">
                <el-option label="换行" value="break_line"></el-option>
                <el-option label="空1字" value="1"></el-option>
                <el-option label="空2字" value="2"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-form>
      </el-collapse-item>
      <el-collapse-item name="question_has_sub">
        <template slot="title">
          <div class="answer-book-style-header">
            <span class="answer-book-style-header-title p-r-10">大小题</span>
            <el-tooltip class="item" effect="dark" placement="top" :open-delay="200">
              <template slot="content">
                <span class="p-r-10" v-if="question_has_sub_cfg.display_analysis === true">有解析</span>
                <span class="p-r-10" v-if="question_has_sub_cfg.display_analysis === false">无解析</span>
                <template v-if="question_has_sub_cfg.display_analysis === true">
                  <template v-if="question_has_sub_cfg.answer_analysis_show_mode">
                    <span class="p-r-10" v-if="question_has_sub_cfg.answer_analysis_show_mode === 'single'">答案解析交叉显示</span>
                    <span class="p-r-10" v-else>答案解析逐块显示</span>
                  </template>
                  <template v-if="question_has_sub_cfg.answer_analysis_order">
                    <span class="p-r-10" v-if="question_has_sub_cfg.answer_analysis_order === 'answer_first'">先答案后解析</span>
                    <span class="p-r-10" v-else>先解析后答案</span>
                  </template>
                  <template v-if="question_has_sub_cfg.answer_analysis_arrangement_mode">
                    <span class="p-r-10" v-if="question_has_sub_cfg.answer_analysis_arrangement_mode === 'break_line'">解析换行</span>
                    <span class="p-r-10" v-else>解析不换行</span>
                  </template>
                  <template v-if="question_has_sub_cfg.answer_analysis_show_mode === 'overall'">
                    <template v-if="question_has_sub_cfg.answer_arrangement_mode">
                      <span class="p-r-10" v-if="question_has_sub_cfg.answer_arrangement_mode === 'break_line'">一行一答案</span>
                      <span class="p-r-10" v-else>一行多答案</span>
                    </template>
                    <template v-if="question_has_sub_cfg.analysis_arrangement_mode">
                      <span class="p-r-10" v-if="question_has_sub_cfg.analysis_arrangement_mode === 'break_line'">一行一解析</span>
                      <span class="p-r-10" v-else>一行多解析</span>
                    </template>
                  </template>
                </template>
                <template v-if="question_has_sub_cfg.children_arrangement_mode && question_has_sub_cfg.answer_analysis_show_mode === 'single'">
                  <span class="p-r-10" v-if="question_has_sub_cfg.children_arrangement_mode === 'break_line'">小题换行</span>
                  <span class="p-r-10" v-else>小题不换行</span>
                </template>
              </template>
              <span class="answer-book-style-header-desc">
                <span class="p-r-10" v-if="question_has_sub_cfg.display_analysis === true">有解析</span>
                <span class="p-r-10" v-if="question_has_sub_cfg.display_analysis === false">无解析</span>
                <template v-if="question_has_sub_cfg.display_analysis === true">
                  <template v-if="question_has_sub_cfg.answer_analysis_show_mode">
                    <span class="p-r-10" v-if="question_has_sub_cfg.answer_analysis_show_mode === 'single'">答案解析交叉显示</span>
                    <span class="p-r-10" v-else>答案解析逐块显示</span>
                  </template>
                  <template v-if="question_has_sub_cfg.answer_analysis_order">
                    <span class="p-r-10" v-if="question_has_sub_cfg.answer_analysis_order === 'answer_first'">先答案后解析</span>
                    <span class="p-r-10" v-else>先解析后答案</span>
                  </template>
                  <template v-if="question_has_sub_cfg.answer_analysis_arrangement_mode">
                    <span class="p-r-10" v-if="question_has_sub_cfg.answer_analysis_arrangement_mode === 'break_line'">解析换行</span>
                    <span class="p-r-10" v-else>解析不换行</span>
                  </template>
                  <template v-if="question_has_sub_cfg.answer_analysis_show_mode === 'overall'">
                    <template v-if="question_has_sub_cfg.answer_arrangement_mode">
                      <span class="p-r-10" v-if="question_has_sub_cfg.answer_arrangement_mode === 'break_line'">一行一答案</span>
                      <span class="p-r-10" v-else>一行多答案</span>
                    </template>
                    <template v-if="question_has_sub_cfg.analysis_arrangement_mode">
                      <span class="p-r-10" v-if="question_has_sub_cfg.analysis_arrangement_mode === 'break_line'">一行一解析</span>
                      <span class="p-r-10" v-else>一行多解析</span>
                    </template>
                  </template>
                </template>
                <template v-if="question_has_sub_cfg.children_arrangement_mode && question_has_sub_cfg.answer_analysis_show_mode === 'single'">
                  <span class="p-r-10" v-if="question_has_sub_cfg.children_arrangement_mode === 'break_line'">小题换行</span>
                  <span class="p-r-10" v-else>小题不换行</span>
                </template>
              </span>
            </el-tooltip>
          </div>
        </template>
        <el-form label-position="right" label-width="160px" size="mini" class="m-t-10">
          <el-form-item label="是否显示解析：">
            <el-radio-group v-model="question_has_sub_cfg.display_analysis">
              <el-radio :label="true">显示</el-radio>
              <el-radio :label="false">不显示</el-radio>
            </el-radio-group>
          </el-form-item>
          <template v-if="question_has_sub_cfg.display_analysis === true">
            <el-form-item label="答案解析模式：">
              <el-radio-group v-model="question_has_sub_cfg.answer_analysis_show_mode">
                <el-radio label="single">答案解析逐题显示</el-radio>
                <el-radio label="overall">答案解析逐块显示</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="答案解析顺序：">
              <el-radio-group v-model="question_has_sub_cfg.answer_analysis_order">
                <el-radio label="answer_first">先答案后解析</el-radio>
                <el-radio label="analysis_first">先解析后答案</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="答案解析排列效果：">
              <el-select v-model="question_has_sub_cfg.answer_analysis_arrangement_mode" style="width: 100px;">
                <el-option label="换行" value="break_line"></el-option>
                <el-option label="空1字" value="1"></el-option>
                <el-option label="空2字" value="2"></el-option>
              </el-select>
            </el-form-item>
            <template v-if="question_has_sub_cfg.answer_analysis_show_mode === 'overall'">
              <el-form-item label="答案排列效果：">
                <el-select v-model="question_has_sub_cfg.answer_arrangement_mode" style="width: 100px;">
                  <el-option label="换行" value="break_line"></el-option>
                  <el-option label="空1字" value="1"></el-option>
                  <el-option label="空2字" value="2"></el-option>
                  <el-option label="换行后加两个硬空" value="break_line_2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="解析排列效果：">
                <el-select v-model="question_has_sub_cfg.analysis_arrangement_mode" style="width: 100px;">
                  <el-option label="换行" value="break_line"></el-option>
                  <el-option label="空1字" value="1"></el-option>
                  <el-option label="空2字" value="2"></el-option>
                  <el-option label="换行后加两个硬空" value="break_line_2"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </template>
          <template v-if="question_has_sub_cfg.answer_analysis_show_mode === 'single'">
            <el-form-item label="小题排列效果：">
              <el-select v-model="question_has_sub_cfg.children_arrangement_mode" style="width: 100px;">
                <el-option label="换行" value="break_line"></el-option>
                <el-option label="空1字" value="1"></el-option>
                <el-option label="空2字" value="2"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-form>
      </el-collapse-item>
      <el-collapse-item name="material">
        <template slot="title">
          <div class="answer-book-style-header">
            <span class="answer-book-style-header-title p-r-10">材料题</span>
            <el-tooltip class="item" effect="dark" placement="top" :open-delay="200">
              <template slot="content">
                <template v-if="material_cfg.children_arrangement_mode">
                  <span class="p-r-10" v-if="material_cfg.children_arrangement_mode === 'break_line'">小题换行</span>
                  <span class="p-r-10" v-else>小题不换行</span>
                </template>
              </template>
              <span class="answer-book-style-header-desc">
                <template v-if="material_cfg.children_arrangement_mode">
                  <span class="p-r-10" v-if="material_cfg.children_arrangement_mode === 'break_line'">小题换行</span>
                  <span class="p-r-10" v-else>小题不换行</span>
                </template>
              </span>
            </el-tooltip>
          </div>
        </template>
        <el-form label-position="right" label-width="160px" size="mini" class="m-t-10">
          <el-form-item label="小题排列效果：">
            <el-select v-model="material_cfg.children_arrangement_mode" style="width: 100px;">
              <el-option label="换行" value="break_line"></el-option>
              <el-option label="空1字" value="1"></el-option>
              <el-option label="空2字" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-collapse-item>
      <el-collapse-item name="extra">
        <template slot="title">
          <div class="answer-book-style-header">
            <span class="answer-book-style-header-title p-r-10">其他</span>
            <el-tooltip class="item" effect="dark" placement="top" :open-delay="200">
              <template slot="content">
                <template v-if="extra_cfg.different_question_arrangement_mode">
                  <span class="p-r-10" v-if="extra_cfg.different_question_arrangement_mode === 'break_line'">不同题型之间换行</span>
                  <span class="p-r-10" v-if="extra_cfg.different_question_arrangement_mode === '1'">不同题型之间空一个字</span>
                  <span class="p-r-10" v-if="extra_cfg.different_question_arrangement_mode === '2'">不同题型之间空两个字</span>
                </template>
              </template>
              <span class="answer-book-style-header-desc">
                <template v-if="extra_cfg.different_question_arrangement_mode">
                  <span class="p-r-10" v-if="extra_cfg.different_question_arrangement_mode === 'break_line'">不同题型之间换行</span>
                  <span class="p-r-10" v-if="extra_cfg.different_question_arrangement_mode === '1'">不同题型之间空一个字</span>
                  <span class="p-r-10" v-if="extra_cfg.different_question_arrangement_mode === '2'">不同题型之间空两个字</span>
                </template>
              </span>
            </el-tooltip>
          </div>
        </template>
        <el-form label-position="right" label-width="160px" size="mini" class="m-t-10">
          <el-form-item label="不同题型之间的排列效果：" label-width="200px">
            <el-select v-model="extra_cfg.different_question_arrangement_mode" style="width: 100px;" clearable>
              <el-option label="换行" value="break_line"></el-option>
              <el-option label="空1字" value="1"></el-option>
              <el-option label="空2字" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>

    <div class="use-boot">
      <div class="tac py-4">
        <button @click="mix()" class="btn btn-primary mt-3 mx-auto">
          混入到当前配置
        </button>
        <br><br>
        <span class="text-muted">
          点击按钮，手动把该配置混入全局配置，【再点页面导航条的"保存"按钮】，则此配置可生效
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mergePeer, findKey } from '@/scripts/helper';
import { fontSize, fontFamilyCn, fontFamilyEn, fontMargin } from '@/assets/styleTool/map';
export default {
  name: 'answerBook',
  components: {},
  props: [],
  data () {
    return {
      findKey,
      fontSize,
      fontFamilyCn,
      fontFamilyEn,
      fontMargin,
      form: {
        actName: 'chapter',
        name: '',
        type: 'teacher',
        answer_cfg: null,
      },
    };
  },
  computed: {
    chapter_cfg () {
      return this.form?.answer_cfg?.chapter_cfg;
    },
    choice_cfg () {
      return this.form?.answer_cfg?.choice_cfg;
    },
    blank_cfg () {
      return this.form?.answer_cfg?.blank_cfg;
    },
    true_or_false_cfg () {
      return this.form?.answer_cfg?.true_or_false_cfg;
    },
    question_has_sub_cfg () {
      return this.form?.answer_cfg?.question_has_sub_cfg;
    },
    material_cfg () {
      return this.form?.answer_cfg?.material_cfg;
    },
    extra_cfg () {
      return this.form?.answer_cfg?.extra_cfg;
    },
  },
  watch: {},
  methods: {

    template () {
      return {
        template1: {
          chapter_cfg: {
            text: '',
            font_size: '',
            font_family_cn: '',
            font_family_en: '',
            text_align: ''
          },
          choice_cfg: {
            answer_mode: 'default',
            display_analysis: false,
            question_arrangement_mode: '1',
            answer_analysis_order: '',
            answer_analysis_arrangement_mode: '',
            answer_group_cfg: {
              delimiter: '',
              spacing: '',
              max_num: ''
            }
          },
          blank_cfg: {
            display_analysis: false,
            question_arrangement_mode: '1',
            answer_analysis_order: '',
            answer_analysis_arrangement_mode: ''
          },
          true_or_false_cfg: {
            display_analysis: false,
            question_arrangement_mode: '1',
            answer_analysis_order: '',
            answer_analysis_arrangement_mode: ''
          },
          question_has_sub_cfg: {
            display_analysis: true,
            answer_analysis_show_mode: 'single',
            answer_analysis_order: 'answer_first',
            answer_analysis_arrangement_mode: 'break_line',
            answer_arrangement_mode: '1',
            analysis_arrangement_mode: '1',
            children_arrangement_mode: ''
          },
          material_cfg: {
            children_arrangement_mode: 'break_line'
          },
          extra_cfg: {
            different_question_arrangement_mode: ''
          }
        }
      };
    },

    mix () {
      this.$parent.styleData.components[0].answer_cfg = this.form.answer_cfg;
      alert('已ok');
    },
  },
  created () {
    if (this.$parent?.styleData?.components.length && this.$parent?.styleData?.components[0].answer_cfg) {
      const cfg_current = this.$parent?.styleData?.components[0].answer_cfg;
      this.form.answer_cfg = mergePeer(this.template().template1, cfg_current);
      console.log(this.form.answer_cfg);
    } else {
      this.form.answer_cfg = this.template().template1;
    }
  },
  // mounted() {},
  // beforeDestroy() {},
  // update() {},
  // activited() {},
  // beforeRouteUpdate() {},
  // filters: {},
};
</script>

<style lang="scss" scoped>
  .sp-page{
    /deep/ .el-collapse-item__header{
      padding: 5px 20px !important;
      border: 1px solid #ddd;
      margin: 10px 0;
    }
  }
</style>
